<template>
    <div class="historyInfo">
        <back></back>
        <div class="item">
            <div class="meeting">
                <div class="row" style="display:flex">
                    <div class="td" style="flex:1.5">公司名称</div>
                    <div class="td" style="flex:3">{{listInfo.name}}</div>
                    <div class="td" style="flex:1.5">联系方式</div>
                    <div class="td" style="flex:3;border:none">{{listInfo.telephone}}</div>
                </div>
                <div class="row" style="display:flex">
                    <div class="td" style="flex:1.5">法人代表</div>
                    <div class="td" style="flex:3">{{listInfo.operName}}</div>
                    <div class="td" style="flex:1.5">经营状态</div>
                    <div class="td" style="flex:3;border:none">{{listInfo.new_status}}</div>
                </div>
                <div class="row" style="display:flex">
                    <div class="td" style="flex:1.5">注册资本</div>
                    <div class="td" style="flex:3">{{listInfo.registCapi}}</div>
                    <div class="td" style="flex:1.5">实缴资本</div>
                    <div class="td" style="flex:3;border:none">{{listInfo.actualCapi}}</div>
                </div>
                <div class="row" style="display:flex">
                    <div class="td" style="flex:1.5">统一社会信用代码</div>
                    <div class="td" style="flex:3">{{listInfo.creditNo}}</div>
                    <div class="td" style="flex:1.5">纳税人识别号</div>
                    <div class="td" style="flex:3;border:none"></div>
                </div>
                <div class="row" style="display:flex">
                    <div class="td" style="flex:1.5">工商注册号</div>
                    <div class="td" style="flex:3">{{listInfo.regNo}}</div>
                    <div class="td" style="flex:1.5">组织机构代码</div>
                    <div class="td" style="flex:3;border:none">{{listInfo.orgNo}}</div>
                </div>
                <div class="row" style="display:flex">
                    <div class="td" style="flex:1.5">进出口企业代码</div>
                    <div class="td" style="flex:3"></div>
                    <div class="td" style="flex:1.5">海关注册编码</div>
                    <div class="td" style="flex:3;border:none"></div>
                </div>
                <div class="row" style="display:flex">
                    <div class="td" style="flex:1.5">英文名</div>
                    <div class="td" style="flex:3">{{listInfo.format_name}}</div>
                    <div class="td" style="flex:1.5">历史名称</div>
                    <div class="td" style="flex:3;border:none">{{listInfo.historyNames}}</div>
                </div>
                <div class="row" style="display:flex">
                    <div class="td" style="flex:1.5">所属行业</div>
                    <div class="td" style="flex:3"></div>
                    <div class="td" style="flex:1.5">成立日期</div>
                    <div class="td" style="flex:3;border:none">{{listInfo.startDate}}</div>
                </div>
                <div class="row" style="display:flex">
                    <div class="td" style="flex:1.5">企业类型</div>
                    <div class="td" style="flex:3">{{listInfo.econKind}}</div>
                    <div class="td" style="flex:1.5">营业期限</div>
                    <div class="td" style="flex:3;border:none">{{listInfo.termEnd}}</div>
                </div>
                <div class="row" style="display:flex">
                    <div class="td" style="flex:1.5">登记机关</div>
                    <div class="td" style="flex:3">{{listInfo.belongOrg}}</div>
                    <div class="td" style="flex:1.5">核准日期</div>
                    <div class="td" style="flex:3;border:none">{{listInfo.checkDate}}</div>
                </div>
                <div class="row" style="display:flex">
                    <div class="td" style="flex:1">注册地址</div>
                    <div class="td" style="flex:5;border:none">{{listInfo.address}}</div>
                </div>
                <div class="row" style="display:flex; border-bottom:none">
                    <div class="td" style="flex:1">经营范围</div>
                    <div class="td" style="flex:5;line-height:20px;border:none">{{listInfo.scope}}</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import back from "../../components/square/back.vue";
export default {
    name: 'QipangpangHistoryInfo',
    components: {
        back
    },
    data() {
        return {
            list: {},
            listInfo: {},
        };
    },

    mounted() {
        this.list = JSON.parse(sessionStorage.getItem('hisInfo'))
        this.listInfo = this.list[0].val.data
        console.log(this.listInfo);

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.historyInfo {
    .item {
        width: 1200px;
        margin: 0 auto;
        .meeting {
            margin-top: 10px;
            border: 1px solid #eeeeee;
            .row {
                font-size: 16px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 62px;
                border-bottom: 1px solid #eeeeee;
                .td {
                    padding-left: 10px;
                    border-right: 1px solid #eeeeee;
                }
            }
        }
    }
}
</style>