<template>
    <div class="back-container">
        <div class="main">
            <img class="img" src="../../assets/images/square/fh.png" alt="" @click="back">
            <span @click="back(text)" class="txt">{{ text }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'back',
    props: {
        text: {
            type: String,
            default: '返回'
        }
    },
    methods: {
        back(val) {
            console.log(val);

            this.$router.back(-1)
        }
    }
}
</script>

<style lang="scss" scoped>
.back-container {
    width: 100%;
    background-color: #515151;
    height: 42px;
    .main {
        display: flex;
        align-items: center;
        width: 1200px;
        height: 100%;
        margin: auto;

        .img {
            width: 16.76px;
            height: 16.76px;
        }
        .txt {
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
        }
    }
}
</style>